import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import Button from "../components/Button"
import { useHeroAssets } from "../hooks/hero"
import { usePosts } from "../hooks/posts"

const StyledContainer = styled(Container)`
  margin-bottom: 5.5555556rem;
`
const AltTitleContainer = styled.div`
  text-align: center;
  margin-bottom: 5.5555556rem;
`
const SecondaryTitle = styled.h2`
  font-size: 2.2222222em;
  margin-bottom: 2.77777778rem;
`
const AltText = styled.p`
  max-width: 510px;
  margin: 0 auto;
`
const Accent = styled.span`
  color: #6c9fff;
`
const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 45px;
  grid-column-gap: 15px;

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media all and (min-width: 769px) {
    grid-gap: 45px;
  }
`
const Post = styled.div``
const StyledLink = styled(Link)`
  text-decoration: none;
`
const PostTitle = styled.h3`
  color: #6c9fff;
  font-size: 1.66666667em;
  margin-bottom: 0;
`
const PostImg = styled.div`
  max-width: 560px;
  height: 250px;
  background-color: transparent;
  margin-bottom: 1.5rem;
  position: relative;
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
  transition: all 0.3s ease-in-out;

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    background: #fff;
    z-index: -1;
    opacity: 1;
  }
`
const PostExcerpt = styled.p`
  margin-bottom: 1.5rem;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const MorePostContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5.5555556rem;
`
const MorePostButton = styled(Button)`
  font-size: 1.77777778em;
  padding: 15px 40px 10px 40px;
`

const PublishedDateRow = styled.div`
  margin-bottom: 1.45rem;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
`

const PublishedText = styled.span`
  margin-right: 10px;
`
const PublishedDate = styled.span``

const MediaPage = () => {
  const { hero, mediaVideo } = useHeroAssets()
  const posts = usePosts()
  return (
    <Layout
      isVideo
      heroOptions={{
        title: <Accent>Blog</Accent>,
        bg: hero,
        video: mediaVideo,
      }}
    >
      <SEO
        title="Blog"
        slug="/blog"
        description="Tekintsd meg legújabb fellépéseinket, és olvasd el sportolóink
            élménybeszámolóit. Légy részese az FlipUnit Production életérzésnek!"
      />
      <StyledContainer>
        <AltTitleContainer>
          <SecondaryTitle className="wow fadeIn">
            <Accent>Pillantson be</Accent> a kulisszák mögé!
          </SecondaryTitle>
          <AltText className="wow fadeIn" data-wow-delay=".2s">
            Tekintsd meg legújabb fellépéseinket, és olvasd el sportolóink
            élménybeszámolóit. Légy részese az FlipUnit Production életérzésnek!
          </AltText>
        </AltTitleContainer>
        <BlogGrid>
          {posts.map(({ slug, title, cover, excerpt, publishDate }, idx) => (
            <Post
              key={idx}
              className="wow fadeIn"
              data-wow-delay={`${(idx * 2) / 10}`}
              itemScope
              itemType="http://schema.org/Article"
            >
              <StyledLink to={slug}>
                <PostTitle itemProp="name">{title}</PostTitle>
              </StyledLink>
              <PublishedDateRow
                itemProp="datePublished"
                dateTime={new Date(publishDate).toISOString().split("T")[0]}
              >
                <PublishedText>Publikálás dátuma:</PublishedText>
                <PublishedDate>
                  {new Date(publishDate).toISOString().split("T")[0]}
                </PublishedDate>
              </PublishedDateRow>
              <StyledLink to={slug}>
                <PostImg bg={cover.src.fluid.src} />
              </StyledLink>
              <PostExcerpt>{excerpt}</PostExcerpt>
              <StyledButtonContainer>
                <Button to={slug}>Tovább</Button>
              </StyledButtonContainer>
            </Post>
          ))}
        </BlogGrid>
        {posts.length > 10 ? (
          <MorePostContainer>
            <MorePostButton className="wow fadeIn" to="/">
              További Cikkek
            </MorePostButton>
          </MorePostContainer>
        ) : null}
      </StyledContainer>
    </Layout>
  )
}

export default MediaPage
